<template>
  <div class="page" id="create-call-bridge">
    <div class="p-3 card">
      <h1 class="m-0">
        {{ $t('call-bridge.create.title') }}
      </h1>

      <p class="m-0">
        {{ $t('call-bridge.create.description') }}
      </p>

      <div class="bridge">
        <div class="p-3 card">
          <div class="mb-4 basic">
            <h4 class="mt-0 mb-2">
              {{ $t('call-bridge.create.form.titles.basic') }}
            </h4>
            <div class="row">
              <div class="col-12">
                <Field
                  :label="$t('call-bridge.create.form.name.label')"
                  :placeholder="$t('call-bridge.create.form.name.placeholder')"
                  :info="$t('call-bridge.create.form.name.info')"
                  v-model="model.name"
                  :error="errors[`name`]"
                >
                </Field>
              </div>
              <div class="col-12 col-md-6">
                <Field
                  :label="
                    $t(
                      'call-bridge.create.form.firstRecipientTrialsCount.label'
                    )
                  "
                  :error="errors[`firstRecipientTrialsCount`]"
                  :info="
                    $t('call-bridge.create.form.firstRecipientTrialsCount.info')
                  "
                >
                  <b-form-spinbutton
                    class="border"
                    v-model="model.firstRecipientTrialsCount"
                    min="1"
                    max="10"
                    wrap
                    placeholder="-"
                  />
                </Field>
              </div>
              <div class="col-12 col-md-6">
                <Field
                  :label="
                    $t(
                      'call-bridge.create.form.secondRecipientTrialsCount.label'
                    )
                  "
                  :error="errors[`secondRecipientTrialsCount`]"
                  :info="
                    $t(
                      'call-bridge.create.form.secondRecipientTrialsCount.info'
                    )
                  "
                >
                  <b-form-spinbutton
                    class="border"
                    v-model="model.secondRecipientTrialsCount"
                    min="1"
                    max="10"
                    wrap
                    placeholder="-"
                  />
                </Field>
              </div>

              <div class="col-12 col-md-6">
                <Field
                  :label="
                    $t(
                      'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.label'
                    )
                  "
                  :error="errors[`firstRecipientDelayMinutesBetweenTrials`]"
                  :info="
                    $t(
                      'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.info'
                    )
                  "
                >
                  <b-form-spinbutton
                    class="border"
                    v-model="model.firstRecipientDelayMinutesBetweenTrials"
                    min="0"
                    max="5"
                    wrap
                    placeholder="-"
                  />
                </Field>
              </div>
              <div class="col-12 col-md-6">
                <Field
                  :label="
                    $t(
                      'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.label'
                    )
                  "
                  :error="errors[`secondRecipientDelayMinutesBetweenTrials`]"
                  :info="
                    $t(
                      'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.info'
                    )
                  "
                >
                  <b-form-spinbutton
                    class="border"
                    v-model="model.secondRecipientDelayMinutesBetweenTrials"
                    min="0"
                    max="5"
                    wrap
                    placeholder="-"
                  />
                </Field>
              </div>
            </div>
          </div>

          <div class="mb-4 sounds">
            <h4 class="mt-0 mb-2">
              {{ $t('call-bridge.create.form.titles.sounds') }}
            </h4>
            <div class="row">
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-bridge.create.form.welcomeSound.label')"
                  :info="$t('call-bridge.create.form.welcomeSound.info')"
                  :error="errors[`welcomeSound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.welcomeSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-bridge.create.form.welcomeSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{
                          $t('call-bridge.create.form.welcomeSound.subtitle')
                        }}
                      </div>
                      <div class="description">
                        {{
                          $t(
                            'call-bridge.create.form.welcomeSound.description',
                            {
                              exts: '.mp3'
                            }
                          )
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-bridge.create.form.alertSound.label')"
                  :info="$t('call-bridge.create.form.alertSound.info')"
                  :error="errors[`alertSound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.alertSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-bridge.create.form.alertSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{ $t('call-bridge.create.form.alertSound.subtitle') }}
                      </div>
                      <div class="description">
                        {{
                          $t('call-bridge.create.form.alertSound.description', {
                            exts: '.mp3'
                          })
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="
                    $t('call-bridge.create.form.firstRecipientSorrySound.label')
                  "
                  :info="
                    $t('call-bridge.create.form.firstRecipientSorrySound.info')
                  "
                  :error="errors[`firstRecipientSorrySound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.firstRecipientSorrySound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{
                          $t(
                            'call-bridge.create.form.firstRecipientSorrySound.title'
                          )
                        }}
                      </h3>
                      <div class="subtitle">
                        {{
                          $t(
                            'call-bridge.create.form.firstRecipientSorrySound.subtitle'
                          )
                        }}
                      </div>
                      <div class="description">
                        {{
                          $t(
                            'call-bridge.create.form.firstRecipientSorrySound.description',
                            {
                              exts: '.mp3'
                            }
                          )
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="
                    $t(
                      'call-bridge.create.form.secondRecipientSorrySound.label'
                    )
                  "
                  :info="
                    $t('call-bridge.create.form.secondRecipientSorrySound.info')
                  "
                  :error="errors[`secondRecipientSorrySound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.secondRecipientSorrySound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{
                          $t(
                            'call-bridge.create.form.secondRecipientSorrySound.title'
                          )
                        }}
                      </h3>
                      <div class="subtitle">
                        {{
                          $t(
                            'call-bridge.create.form.secondRecipientSorrySound.subtitle'
                          )
                        }}
                      </div>
                      <div class="description">
                        {{
                          $t(
                            'call-bridge.create.form.secondRecipientSorrySound.description',
                            {
                              exts: '.mp3'
                            }
                          )
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>

              <div class="row align-items-center px-4 flex-fill">
                <div class="col-12 col-md-6">
                  <b-form-checkbox v-model="hasWarning">
                    {{ $t('call-bridge.create.hasWarning') }}
                  </b-form-checkbox>
                </div>
                <div class="col-12 col-md-6 flex-fill" v-if="hasWarning">
                  <Field
                    :label="
                      $t('call-bridge.create.form.warningTimeBeforeEnd.label')
                    "
                    :error="errors[`warningTimeBeforeEnd`]"
                    :info="
                      $t('call-bridge.create.form.warningTimeBeforeEnd.info')
                    "
                  >
                    <b-form-input
                      class="border"
                      v-model="model.warningTimeBeforeEnd"
                      type="number"
                      min="1"
                      @keydown="onlyAcceptNumbers"
                    />
                  </Field>
                </div>
              </div>
              <div class="col-12" v-if="hasWarning">
                <Field
                  :label="$t('call-bridge.create.form.warningSound.label')"
                  :info="$t('call-bridge.create.form.warningSound.info')"
                  :error="errors[`warningSound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.warningSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-bridge.create.form.warningSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{
                          $t('call-bridge.create.form.warningSound.subtitle')
                        }}
                      </div>
                      <div class="description">
                        {{
                          $t(
                            'call-bridge.create.form.warningSound.description',
                            {
                              exts: '.mp3'
                            }
                          )
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
            </div>
          </div>

          <div class="mb-4 callers">
            <h4 class="mt-0 mb-2">
              {{ $t('call-bridge.create.form.titles.callerIds') }}
            </h4>
            <div class="callers-container row">
              <div
                v-for="(caller, idx) in model.callers"
                :key="caller.id"
                class="align-items-start col-12 row"
              >
                <div class="col-11 row">
                  <div class="col-12 col-md-6">
                    <Field
                      :label="
                        $t(
                          'call-bridge.create.form.callerIds.destination.label'
                        )
                      "
                      :info="
                        $t('call-bridge.create.form.callerIds.destination.info')
                      "
                      :error="errors[`callers[${idx}].destination`]"
                    >
                      <b-form-select
                        class="border"
                        @change="onCountryChange($event, idx, caller.id)"
                        v-model="model.callers[idx].destination"
                      >
                        <b-form-select-option
                          v-for="country in countries"
                          :key="country.code"
                          :value="country.code"
                          :disabled="
                            model.callers[idx].destination !== country.code &&
                              selectedCountries.includes(country.code)
                          "
                        >
                          {{ country.emoji }} {{ country.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </Field>
                  </div>

                  <div class="col-12 col-md-6">
                    <Field
                      :label="
                        $t('call-bridge.create.form.callerIds.callerId.label')
                      "
                      :info="
                        $t('call-bridge.create.form.callerIds.callerId.info')
                      "
                      :error="errors[`callers[${idx}].callerNumber`]"
                    >
                      <b-form-select
                        class="border"
                        v-model="model.callers[idx].callerNumber"
                        :options="callerNumbers"
                      />
                    </Field>
                  </div>
                </div>

                <div class="mt-4 col-1">
                  <b-button
                    class="px-3 py-2"
                    variant="danger"
                    @click="handleRemoveCaller(idx)"
                    v-if="model.callers.length > 1"
                  >
                    <i class="fa fa-trash"></i>
                  </b-button>
                </div>
              </div>

              <p class="mx-3 text-danger">
                {{ errors[`callers`] }}
              </p>

              <div class="col-12">
                <b-button class="px-3 py-2" @click="handleAddCaller">
                  {{ $t('call-bridge.create.form.callerIds.add') }}
                </b-button>
              </div>
            </div>
          </div>

          <b-button
            class="mt-4 px-3 py-2"
            variant="primary"
            @click="onSubmit"
            :disabled="loading"
          >
            {{ $t('call-bridge.create.form.create') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import Dropzone from '../../../elements/Dropzone.vue'
import { onlyAcceptNumbers, removeNullEntries } from '../../../utils/utils.js'
import { array, mixed, number, object, string } from 'yup'
import vocabService from '../../../services/vocab.service.js'
import { yupToKV } from '../../../utils/yup.js'
import CallBridgesService from '../../../services/callBridge.service.js'

const defaultCaller = {
  id: new Date().getTime(),
  destination: '',
  callerNumber: ''
}

export default {
  name: 'CreateCallBridge',
  components: {
    Field,
    Dropzone
  },
  data() {
    return {
      model: {
        name: '',
        firstRecipientTrialsCount: 1,
        secondRecipientTrialsCount: 1,
        firstRecipientDelayMinutesBetweenTrials: 0,
        secondRecipientDelayMinutesBetweenTrials: 0,
        warningTimeBeforeEnd: 0,
        welcomeSound: null,
        firstRecipientSorrySound: null,
        secondRecipientSorrySound: null,
        warningSound: null,
        alertSound: null,
        callers: [{ ...defaultCaller }]
      },
      hasWarning: false,
      errors: {},
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      loading: false
    }
  },
  computed: {
    schema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('call-bridge.create.form.name.required')),
        firstRecipientTrialsCount: number()
          .required(
            this.$t(
              'call-bridge.create.form.firstRecipientTrialsCount.required'
            )
          )
          .min(
            1,
            this.$t('call-bridge.create.form.firstRecipientTrialsCount.min'),
            { min: 1 }
          )
          .max(
            10,
            this.$t('call-bridge.create.form.firstRecipientTrialsCount.max'),
            { max: 10 }
          ),
        secondRecipientTrialsCount: number()
          .required(
            this.$t(
              'call-bridge.create.form.secondRecipientTrialsCount.required'
            )
          )
          .min(
            1,
            this.$t('call-bridge.create.form.secondRecipientTrialsCount.min'),
            { min: 1 }
          )
          .max(
            10,
            this.$t('call-bridge.create.form.secondRecipientTrialsCount.max'),
            { max: 10 }
          ),
        firstRecipientDelayMinutesBetweenTrials: number()
          .required(
            this.$t(
              'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.required'
            )
          )
          .min(
            0,
            this.$t(
              'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.min',
              { min: 0 }
            )
          )
          .max(
            5,
            this.$t(
              'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.max',
              { max: 5 }
            )
          ),
        secondRecipientDelayMinutesBetweenTrials: number()
          .required(
            this.$t(
              'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.required'
            )
          )
          .min(
            0,
            this.$t(
              'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.min',
              { min: 0 }
            )
          )
          .max(
            5,
            this.$t(
              'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.max',
              { max: 5 }
            )
          ),

        welcomeSound: mixed().required(
          this.$t('call-bridge.create.form.welcomeSound.required')
        ),
        firstRecipientSorrySound: mixed().required(
          this.$t('call-bridge.create.form.firstRecipientSorrySound.required')
        ),
        secondRecipientSorrySound: mixed().required(
          this.$t('call-bridge.create.form.secondRecipientSorrySound.required')
        ),
        warningSound: mixed().when('hasWarning', value => {
          if (this.hasWarning) {
            return mixed().required(
              this.$t('call-bridge.create.form.warningSound.required')
            )
          } else {
            return mixed()
          }
        }),
        warningTimeBeforeEnd: number().when('warningSound', value => {
          if (this.hasWarning) {
            return number()
              .required(
                this.$t('call-bridge.create.form.warningTimeBeforeEnd.required')
              )
              .min(
                1,
                this.$t('call-bridge.create.form.warningTimeBeforeEnd.min', {
                  min: 1
                })
              )
          } else {
            return number()
          }
        }),
        alertSound: mixed().required(
          this.$t('call-bridge.create.form.alertSound.required')
        ),
        callers: array()
          .of(
            object().shape({
              destination: string()
                .trim()
                .required(
                  this.$t(
                    'call-bridge.create.form.callerIds.destination.required'
                  )
                ),
              callerNumber: string()
                .trim()
                .required(
                  this.$t('call-bridge.create.form.callerIds.callerId.required')
                )
            })
          )
          .min(1, this.$t('call-bridge.create.form.callerIds.min'))
      })
    }
  },
  methods: {
    onlyAcceptNumbers,
    // handlers
    handleAddCaller() {
      this.model.callers.push({
        ...defaultCaller,
        id: new Date().getTime()
      })
    },
    handleRemoveCaller(index) {
      const caller = this.model.callers[index]
      this.selectedCountries = this.selectedCountries.filter(
        country => country !== caller.destination
      )

      this.model.callers.splice(index, 1)
      this.errors[`callers[${index}].destination`] = null
      this.errors[`callers[${index}].callerNumber`] = null
    },
    onCountryChange(value, idx) {
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
    },
    // api calls
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    },
    async onSubmit() {
      try {
        this.loading = true
        this.errors = {}
        // get the data and remove all keys that's null
        const data = removeNullEntries({
          ...this.model,
          firstRecipientTrialsCount: parseInt(
            this.model.firstRecipientTrialsCount
          ),
          secondRecipientTrialsCount: parseInt(
            this.model.secondRecipientTrialsCount
          ),
          firstRecipientDelayMinutesBetweenTrials: parseInt(
            this.model.firstRecipientDelayMinutesBetweenTrials
          ),
          secondRecipientDelayMinutesBetweenTrials: parseInt(
            this.model.secondRecipientDelayMinutesBetweenTrials
          ),
          warningTimeBeforeEnd: parseInt(this.model.warningTimeBeforeEnd),
          welcomeSound: this.model.welcomeSound
            ? this.model.welcomeSound[0]
            : null,
          firstRecipientSorrySound: this.model.firstRecipientSorrySound
            ? this.model.firstRecipientSorrySound[0]
            : null,
          secondRecipientSorrySound: this.model.secondRecipientSorrySound
            ? this.model.secondRecipientSorrySound[0]
            : null,
          warningSound:
            this.model.warningSound && this.hasWarning
              ? this.model.warningSound[0]
              : null,
          warningTimeBeforeEnd: this.hasWarning
            ? parseInt(this.model.warningTimeBeforeEnd)
            : null,
          alertSound: this.model.alertSound ? this.model.alertSound[0] : null
        })
        console.log(data)

        await this.schema.validate(data, { abortEarly: false }).catch(err => {
          this.errors = yupToKV(err)
        })

        if (!this.schema.isValidSync(data)) {
          return
        }

        // upload files
        const welcomeSoundPromise = CallBridgesService.uploadBridgeSound(
          data.welcomeSound
        )
        const alertSoundPromise = CallBridgesService.uploadBridgeSound(
          data.alertSound
        )
        const firstRecipientSorrySoundPromise = CallBridgesService.uploadBridgeSound(
          data.firstRecipientSorrySound
        )

        const secondRecipientSorrySoundPromise = CallBridgesService.uploadBridgeSound(
          data.secondRecipientSorrySound
        )

        const warningSoundPromise = CallBridgesService.uploadBridgeSound(
          data.warningSound
        )

        const [
          welcomeSoundRes,
          alertSoundRes,
          firstRecipientSorrySoundRes,
          secondRecipientSorrySoundRes,
          warningSoundRes
        ] = await Promise.all([
          welcomeSoundPromise,
          alertSoundPromise,
          firstRecipientSorrySoundPromise,
          secondRecipientSorrySoundPromise,
          warningSoundPromise
        ])

        const {
          welcomeSound,
          alertSound,
          firstRecipientSorrySound,
          secondRecipientSorrySound,
          warningSound,
          ...rest
        } = data

        const modelData = {
          ...rest,
          welcomeSoundFileName: welcomeSoundRes.data.originalName,
          welcomeSoundFilePath: welcomeSoundRes.data.path,
          firstRecipientSorrySoundFileName:
            firstRecipientSorrySoundRes.data.originalName,
          firstRecipientSorrySoundFilePath:
            firstRecipientSorrySoundRes.data.path,
          secondRecipientSorrySoundFileName:
            secondRecipientSorrySoundRes.data.originalName,
          secondRecipientSorrySoundFilePath:
            secondRecipientSorrySoundRes.data.path,
          warningSoundFileName:
            warningSoundRes && warningSoundRes.data.originalName,
          warningSoundFilePath: warningSoundRes && warningSoundRes.data.path,
          alertSoundFileName: alertSoundRes.data.originalName,
          alertSoundFilePath: alertSoundRes.data.path
        }

        await CallBridgesService.createBridge(modelData)
        this.toast(this.$t('call-bridge.create.toasts.bridge-created'))
        this.$router.push({ name: 'call-bridge-flows' })
      } catch (err) {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message
        console.error(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getCountries()
    this.getCallerNumbers()
  }
}
</script>

<style scoped lang="scss"></style>
