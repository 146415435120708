var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"create-call-bridge"}},[_c('div',{staticClass:"p-3 card"},[_c('h1',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.title'))+" ")]),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.description'))+" ")]),_c('div',{staticClass:"bridge"},[_c('div',{staticClass:"p-3 card"},[_c('div',{staticClass:"mb-4 basic"},[_c('h4',{staticClass:"mt-0 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.titles.basic'))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.name.label'),"placeholder":_vm.$t('call-bridge.create.form.name.placeholder'),"info":_vm.$t('call-bridge.create.form.name.info'),"error":_vm.errors[`name`]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('Field',{attrs:{"label":_vm.$t(
                    'call-bridge.create.form.firstRecipientTrialsCount.label'
                  ),"error":_vm.errors[`firstRecipientTrialsCount`],"info":_vm.$t('call-bridge.create.form.firstRecipientTrialsCount.info')}},[_c('b-form-spinbutton',{staticClass:"border",attrs:{"min":"1","max":"10","wrap":"","placeholder":"-"},model:{value:(_vm.model.firstRecipientTrialsCount),callback:function ($$v) {_vm.$set(_vm.model, "firstRecipientTrialsCount", $$v)},expression:"model.firstRecipientTrialsCount"}})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('Field',{attrs:{"label":_vm.$t(
                    'call-bridge.create.form.secondRecipientTrialsCount.label'
                  ),"error":_vm.errors[`secondRecipientTrialsCount`],"info":_vm.$t(
                    'call-bridge.create.form.secondRecipientTrialsCount.info'
                  )}},[_c('b-form-spinbutton',{staticClass:"border",attrs:{"min":"1","max":"10","wrap":"","placeholder":"-"},model:{value:(_vm.model.secondRecipientTrialsCount),callback:function ($$v) {_vm.$set(_vm.model, "secondRecipientTrialsCount", $$v)},expression:"model.secondRecipientTrialsCount"}})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('Field',{attrs:{"label":_vm.$t(
                    'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.label'
                  ),"error":_vm.errors[`firstRecipientDelayMinutesBetweenTrials`],"info":_vm.$t(
                    'call-bridge.create.form.firstRecipientDelayMinutesBetweenTrials.info'
                  )}},[_c('b-form-spinbutton',{staticClass:"border",attrs:{"min":"0","max":"5","wrap":"","placeholder":"-"},model:{value:(_vm.model.firstRecipientDelayMinutesBetweenTrials),callback:function ($$v) {_vm.$set(_vm.model, "firstRecipientDelayMinutesBetweenTrials", $$v)},expression:"model.firstRecipientDelayMinutesBetweenTrials"}})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('Field',{attrs:{"label":_vm.$t(
                    'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.label'
                  ),"error":_vm.errors[`secondRecipientDelayMinutesBetweenTrials`],"info":_vm.$t(
                    'call-bridge.create.form.secondRecipientDelayMinutesBetweenTrials.info'
                  )}},[_c('b-form-spinbutton',{staticClass:"border",attrs:{"min":"0","max":"5","wrap":"","placeholder":"-"},model:{value:(_vm.model.secondRecipientDelayMinutesBetweenTrials),callback:function ($$v) {_vm.$set(_vm.model, "secondRecipientDelayMinutesBetweenTrials", $$v)},expression:"model.secondRecipientDelayMinutesBetweenTrials"}})],1)],1)])]),_c('div',{staticClass:"mb-4 sounds"},[_c('h4',{staticClass:"mt-0 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.titles.sounds'))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.welcomeSound.label'),"info":_vm.$t('call-bridge.create.form.welcomeSound.info'),"error":_vm.errors[`welcomeSound`]}},[_c('Dropzone',{attrs:{"options":{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }},model:{value:(_vm.model.welcomeSound),callback:function ($$v) {_vm.$set(_vm.model, "welcomeSound", $$v)},expression:"model.welcomeSound"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.welcomeSound.title'))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.welcomeSound.subtitle'))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.welcomeSound.description', { exts: '.mp3' } ))+" ")])])])],1)],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.alertSound.label'),"info":_vm.$t('call-bridge.create.form.alertSound.info'),"error":_vm.errors[`alertSound`]}},[_c('Dropzone',{attrs:{"options":{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }},model:{value:(_vm.model.alertSound),callback:function ($$v) {_vm.$set(_vm.model, "alertSound", $$v)},expression:"model.alertSound"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.alertSound.title'))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.alertSound.subtitle'))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.alertSound.description', { exts: '.mp3' }))+" ")])])])],1)],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.firstRecipientSorrySound.label'),"info":_vm.$t('call-bridge.create.form.firstRecipientSorrySound.info'),"error":_vm.errors[`firstRecipientSorrySound`]}},[_c('Dropzone',{attrs:{"options":{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }},model:{value:(_vm.model.firstRecipientSorrySound),callback:function ($$v) {_vm.$set(_vm.model, "firstRecipientSorrySound", $$v)},expression:"model.firstRecipientSorrySound"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.firstRecipientSorrySound.title' ))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.firstRecipientSorrySound.subtitle' ))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.firstRecipientSorrySound.description', { exts: '.mp3' } ))+" ")])])])],1)],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('Field',{attrs:{"label":_vm.$t(
                    'call-bridge.create.form.secondRecipientSorrySound.label'
                  ),"info":_vm.$t('call-bridge.create.form.secondRecipientSorrySound.info'),"error":_vm.errors[`secondRecipientSorrySound`]}},[_c('Dropzone',{attrs:{"options":{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }},model:{value:(_vm.model.secondRecipientSorrySound),callback:function ($$v) {_vm.$set(_vm.model, "secondRecipientSorrySound", $$v)},expression:"model.secondRecipientSorrySound"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.secondRecipientSorrySound.title' ))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.secondRecipientSorrySound.subtitle' ))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.secondRecipientSorrySound.description', { exts: '.mp3' } ))+" ")])])])],1)],1),_c('div',{staticClass:"row align-items-center px-4 flex-fill"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-checkbox',{model:{value:(_vm.hasWarning),callback:function ($$v) {_vm.hasWarning=$$v},expression:"hasWarning"}},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.hasWarning'))+" ")])],1),(_vm.hasWarning)?_c('div',{staticClass:"col-12 col-md-6 flex-fill"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.warningTimeBeforeEnd.label'),"error":_vm.errors[`warningTimeBeforeEnd`],"info":_vm.$t('call-bridge.create.form.warningTimeBeforeEnd.info')}},[_c('b-form-input',{staticClass:"border",attrs:{"type":"number","min":"1"},on:{"keydown":_vm.onlyAcceptNumbers},model:{value:(_vm.model.warningTimeBeforeEnd),callback:function ($$v) {_vm.$set(_vm.model, "warningTimeBeforeEnd", $$v)},expression:"model.warningTimeBeforeEnd"}})],1)],1):_vm._e()]),(_vm.hasWarning)?_c('div',{staticClass:"col-12"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.warningSound.label'),"info":_vm.$t('call-bridge.create.form.warningSound.info'),"error":_vm.errors[`warningSound`]}},[_c('Dropzone',{attrs:{"options":{
                    paramName: 'file',
                    acceptedFiles: '.mp3',
                    maxFiles: 1
                  }},model:{value:(_vm.model.warningSound),callback:function ($$v) {_vm.$set(_vm.model, "warningSound", $$v)},expression:"model.warningSound"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.warningSound.title'))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.warningSound.subtitle'))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t( 'call-bridge.create.form.warningSound.description', { exts: '.mp3' } ))+" ")])])])],1)],1):_vm._e()])]),_c('div',{staticClass:"mb-4 callers"},[_c('h4',{staticClass:"mt-0 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.titles.callerIds'))+" ")]),_c('div',{staticClass:"callers-container row"},[_vm._l((_vm.model.callers),function(caller,idx){return _c('div',{key:caller.id,staticClass:"align-items-start col-12 row"},[_c('div',{staticClass:"col-11 row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('Field',{attrs:{"label":_vm.$t(
                        'call-bridge.create.form.callerIds.destination.label'
                      ),"info":_vm.$t('call-bridge.create.form.callerIds.destination.info'),"error":_vm.errors[`callers[${idx}].destination`]}},[_c('b-form-select',{staticClass:"border",on:{"change":function($event){return _vm.onCountryChange($event, idx, caller.id)}},model:{value:(_vm.model.callers[idx].destination),callback:function ($$v) {_vm.$set(_vm.model.callers[idx], "destination", $$v)},expression:"model.callers[idx].destination"}},_vm._l((_vm.countries),function(country){return _c('b-form-select-option',{key:country.code,attrs:{"value":country.code,"disabled":_vm.model.callers[idx].destination !== country.code &&
                            _vm.selectedCountries.includes(country.code)}},[_vm._v(" "+_vm._s(country.emoji)+" "+_vm._s(country.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('Field',{attrs:{"label":_vm.$t('call-bridge.create.form.callerIds.callerId.label'),"info":_vm.$t('call-bridge.create.form.callerIds.callerId.info'),"error":_vm.errors[`callers[${idx}].callerNumber`]}},[_c('b-form-select',{staticClass:"border",attrs:{"options":_vm.callerNumbers},model:{value:(_vm.model.callers[idx].callerNumber),callback:function ($$v) {_vm.$set(_vm.model.callers[idx], "callerNumber", $$v)},expression:"model.callers[idx].callerNumber"}})],1)],1)]),_c('div',{staticClass:"mt-4 col-1"},[(_vm.model.callers.length > 1)?_c('b-button',{staticClass:"px-3 py-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handleRemoveCaller(idx)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()],1)])}),_c('p',{staticClass:"mx-3 text-danger"},[_vm._v(" "+_vm._s(_vm.errors[`callers`])+" ")]),_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"px-3 py-2",on:{"click":_vm.handleAddCaller}},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.callerIds.add'))+" ")])],1)],2)]),_c('b-button',{staticClass:"mt-4 px-3 py-2",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('call-bridge.create.form.create'))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }